import { get, post} from './http'

export default{
    getPositionList:paramas=>{
        return get('/h5Post/getPostList',paramas);
    },
    getPositionDetail:paramas=>{
        return get('/h5Post/getPostDetails',paramas);
    },
    getUserInfo:paramas=>{
        return post('/h5Post/getUserInfo',paramas);
    },
    
    getMpYouCaiConfig:paramas=>{
        return post('/wechat/getMpYouCaiConfig',paramas);
    },
    uploadFile:paramas=>{
        return post('/uploadFile/uploadFile',paramas);
    },
    applyPost:paramas=>{
        return post('h5Post/applyPost',paramas);
    },
    getQuestionlist:paramas=>{
      return post('/h5Post/questionList',paramas);
    },
    getquestionDetail:paramas=>{
      return post('h5Post/questionDetail',paramas);
    },

    authorize:paramas=>{
      return post('/wechat/authorize',paramas);
    },
    activityApply:paramas=>{
      return post('/h5Activity/activityApply',paramas);
    },
    jsApiPay:paramas=>{
      return post('/wechat/jsApiPay',paramas);
    },
    getActivityShare:paramas=>{
      return get('/h5Activity/getActivityShare',paramas);
    },
    getLinkShare:paramas=>{
      return post('/linkH5/linkH5Share',paramas);
    },
    applyActivity:paramas=>{
      return post('/linkH5/applyActivity',paramas);
    },
    incrBrowCount:paramas=>{
      return post('/linkH5/incrBrowCount',paramas);
    },
    
}