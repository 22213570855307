<template>
    <div class="count-downview" v-if="time>0">
        <div v-if="type" :style="{color:type == 1 ?'#0A86F8' : ''}">
            {{type == '1' ? '距离结束：' : '讲课截止：'}}
        </div>
        <div class="hour">
            <span>{{hou[0]}}{{hou[1]}}</span>
        </div>
        <div class="colon"> : </div>
        <div class="minute">
            <span>{{min[0]}}{{min[1]}}</span>
        </div>
        <div class="colon"> : </div>
        <div class="second">
            <span>{{sec[0]}}{{sec[1]}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'countDown',
        data() {
            return {
                hou: '00',
                min: '00',
                sec: '00',
                interval: '',
                liveTime: '',
                time:''
            }
        },
		
        props: {
            remainingTime: String,
            index: Number,
            type: String,
        },
        watch: {
            remainingTime: {
                handler(newValue, oldValue) {
                    console.log(newValue,oldValue)
                },
                // immediate: true,
                deep: true
            }
        },
        onShow() {

        },
        destroyed() {
            clearInterval(this.interval)
        },
        created() {
            this.time = Number(this.remainingTime)
            this.interval = setInterval(() => {
                this.countDown();
            }, 1000);
        },
        methods: {
            countDown() {
                if(this.time < 0){
                    clearInterval(this.interval);
                    return false;
                }
                this.hou = Math.floor(this.time / 3600) >= 10 ? Math.floor(this.time / 3600) + '' : '0' + Math.floor(this.time / 3600);
                this.min = Math.floor(this.time / 60) % 60 >= 10 ? Math.floor(this.time / 60) % 60 + '' : '0' + Math.floor(this.time / 60) % 60;
                this.sec = this.time % 60 >= 10 ? this.time % 60 + '' : '0' + this.time % 60;
                this.time--;
            },

        }
    }
</script>

<style  scoped>
    .count-downview {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        font-size: 0;
    }
    .count-downview span {
        display: inline-block;
        height: 40rpx;
        width: 40rpx;
        text-align: center;
        line-height: 36rpx;
        margin-left: 2rpx;
        padding: 2rpx 6rpx;
        color: #fff;
        background-color: #0A86F8;
        font-size: 26rpx;
        border-radius: 5rpx;
        box-sizing: border-box;
        font-weight: bold;
    }

    .count-downview div{
        font-size: 30rpx;
    }
    .colon{
        padding:0 2rpx;
    }

    .left-radius {
        border-top-left-radius: 5rpx;
        border-bottom-left-radius: 5rpx;
    }

    .right-radius {
        border-top-left-radius: 5rpx;
        border-bottom-left-radius: 5rpx;
    }
</style>
