<template>
  <div class="container">
    <div class="topcenter">
      <div class="top">
          <img src="../assets/logo.png" alt="" class="logo">
          <img src="../assets/logotitle.png" alt="" class="logotitle">
      </div>
      <img src="../assets/user.png" v-if="!userInfo.headimgurl" alt="" class="login" @click="openwechat">
      <img :src="userInfo.headimgurl" v-else alt="" class="login">
    </div>
    <div v-if="list.length" class="listContents">
      <div class="contents" v-for="(item,index) in list" :key="index" @click="toDetail(item)" >
        <span class="title">{{item.title}}</span>
        <div class="content">
          <span class="description" v-if="item.content">{{item.content}}</span>
          <span class="description" v-if="item.answerContent">{{item.answerContent}}</span>
          <img v-if="item.contentImgUrl" :src="item.contentImgUrl">
        </div>
        <div class="otherInfo" v-if="!type" ><div style="display: inline-block;margin-right: 3px;" >{{item.labelCodeStr||''}} · </div> {{item.browseCount}}人次浏览</div>
        <div class="otherInfo" v-else >{{item.trackTypeStr}} · <div style="display: inline-block;margin-right: 3px;" >{{item.labelCodeStr||''}} · </div> {{item.createTimeStr}} </div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data(){
    return{
      type:false,
      query:{
          pageNum:1,
          pageSize:15
      },
      userInfo:{
          headimgurl:''
      },
      list:[]
    }
  },
  methods:{
    toDetail(item){
      
      if(localStorage.getItem('token')){
        this.$router.push({path:`/answerdetail/${item.questionId}`})
      } else {
        let url = encodeURI(window.location.href)
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxca069443762320ec&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
      }
    },
    openwechat(){
        let url = encodeURI(window.location.href)
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxca069443762320ec&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
    },
    GetParam(url, code) {
      url = url + "";
      let regstr = "/(\\?|\\&)" + code + "=([^\\&]+)/";
      let reg = eval(regstr);
      //eval可以将 regstr字符串转换为 正则表达式
      let result = url.match(reg);
      if (result && result[2]) {
          return result[2];
      }
    },
    handleScroll(){
        //变量scrollTop是滚动条滚动时，距离顶部的距离
        var scrollTop = document.documentElement.scrollTop||document.body.scrollTop || window.pageYOffset;
        //变量windowHeight是可视区的高度
        var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        //变量scrollHeight是滚动条的总高度
        var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
        //滚动条到底部的条件
          // window.alert(`${parseFloat(scrollTop+windowHeight)}${parseFloat(scrollHeight)}`)
          console.log(scrollTop+windowHeight,scrollHeight)
        if(scrollTop+windowHeight>=scrollHeight &&this.list.length !==0){
            this.query.pageNum++
            this.getlist() // 加载的列表数据
        }
    },
    getlist(){
      this.$http.getQuestionlist(this.query).then(res=>{
        console.log(res)
        if(res.status == 1){
          if(this.query.pageNum == 1){
            this.list = res.data.list
          } else {
            this.list = this.list.concat(res.data.list)
          }
        }
      })
    }
  },
  created(){
    let shareConfig = {
        title: '橙蟹企服-财务人共享与协作平台', // 分享标题
        link: window.location.origin + '/answer', // 分享链接
        desc:'',
        imgUrl: 'https://oss.blockchain.orancrab.com/prod/static/sharelogo.jpg',
    }
    let that = this
    this.$wx.ready(function(){
        that.$wx.updateAppMessageShareData(shareConfig);
        that.$wx.updateTimelineShareData(shareConfig);
    })
    
    let code = this.GetParam(window.location.href, "code")
    if(code){
        this.$http.getUserInfo({code:code}).then(res=>{
            if(res.status == 1){
                this.userInfo = res.data
                localStorage.setItem('token',res.data.userToken)
                localStorage.setItem('info',JSON.stringify(this.userInfo))
            } else {
                  this.userInfo = JSON.parse(localStorage.getItem('info'))
            }
        })
    }
    if(JSON.parse(localStorage.getItem('info'))){
        this.userInfo = JSON.parse(localStorage.getItem('info'))
    }
    this.getlist()
    window.addEventListener('scroll',this.handleScroll)
  },
  destroyed(){
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style scoped>
.container{
  height: 100%;
  width: 100%;
  background: white;
}
.topcenter{
    position: fixed;
    top: 0;
    height: 60px;
    width: 100%;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    border-bottom: 1px solid #ddd;
}
.topcenter .logo{
    width: 40px;
    height: 25px;
    /* background: red; */
    margin-left: 10px;
}
.top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.topcenter .logotitle{
    width: 162px;
    height: 40px;
    /* background: green; */
    
}
.topcenter .login{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
}
.listContents{
  margin-top: 60px;
}
.contents{
		display: flex;
		flex-direction: column;
		width: 100%;
		height: auto;
}
.contents .title{
  color: #1a1a1a;
  font-size: 17px;
  letter-spacing: 0.34px;
  line-height: 24px;
  margin: 0 8px;
  margin-top: 15px;
  font-weight: bold;
  word-break: break-word;
  text-align: left;
}
.content{
  margin: 0 8px;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.content .description{
  color: #444444;
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  flex: 1;
  text-align: left;
}
.content img{
  width: 92px;
  height: 63px;
  border-radius: 5px;
}
.otherInfo{
  margin-top: 10px;
  margin-left: 8px;
  color: #999999;
  font-size: 13px;
  letter-spacing: 0.28px;
  line-height: 18px;
  max-width: 180px;
  text-align: left;
}
.line{
  width: 100%;
  height: 10px;
  background: #f6f6f6;
  margin-top: 13px;
}

</style>