import VueRouter from 'vue-router';
import Vue from 'vue'
Vue.use(VueRouter)

import index from '../pages/index.vue'
import detail from '../pages/positiondetail.vue'
import login from '../pages/login.vue'
import answer from '../pages/answer.vue'
import baseanswer from '../pages/baseanswer.vue'
import answerdetail from '../pages/answerdetail.vue'
import activity from '../pages/activity.vue'

const router =  new VueRouter({
    mode:'history',
    routes:[
        {
            path:'/',
            name:'home',
            component:index
        },
        {
            path:'/detail/:postId',
            name:'detail',
            component:detail
        },
        {
            path:'/login',
            name:'login',
            component:login
        },{
          path:'/answer',
          name:'baseanswer',
          component:baseanswer
        },{
            path:'/answer/:linkId',
            name:'answer',
            component:answer
        },{
          path:'/answerdetail/:questionId',
          name:'answerdetail',
          component:answerdetail
        },{
          path:'/activity/:activityId',
          name:'activity',
          component:activity
        }
    ]
})
router.beforeEach((to,from,next)=>{
    // if (to.name == 'detail') {
    //     // 校验微信授权时存储的微信授权信息
    //     let wxInfo = sessionStorage.getItem("code_key");
    //     // 没有用户信息（即未进行微信授权）
    //     if (!wxInfo) {
    //         // 存储默认打开时访问的页面路径
    //         sessionStorage.setItem("wxRedirectUrl", to.path);
    //         // 处理URL地址栏所携带的参数
    //         let search = window.location.search;
    //         if (search.includes("params_key") && search.includes("&")) {
    //             search = search.split("&")[0];
    //         }
    //         // 微信授权获取用户信息指定的路径
    //         let redirect_uri = encodeURIComponent(`https://cx.blockchain.hyonline.online/blockchain_api_dev/`)
    //         // 随机参数（可不传）
    //         // 随机参数（可不传）
    //         let randomParams = '&state=123';
    //         window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxca069443762320ec&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base${randomParams}#wechat_redirect`;
    //         return;
    //     }
    //     next();
    // } 
    next()
})
router.beforeEach((to, from, next) => {
if (window._hmt) {
    if (to.path) {
    window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
}
next()
})

export default router
