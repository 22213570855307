import Vue from 'vue'
import App from './App.vue'
import router from './route'
import wx from 'weixin-js-sdk'
import './assets/reset.css'
import 'lib-flexible'
import http from './utils/api'


Vue.config.productionTip = false
Vue.prototype.$http = http
Vue.prototype.$wx = wx

// 百度统计注入
var _hmt = _hmt || [];
window._hmt = _hmt;
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?b4c0a23011a5c565ef6745db2bb19855";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();

// h5分享
(function(){
  var hashurl= window.location.href
  http.getMpYouCaiConfig({url:hashurl}).then(res=>{
      if(res.status == 1){
          wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appId, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
              signature: res.data.signature,// 必填，签名，见附录1
              jsApiList: [
                  'updateTimelineShareData',
                  'openLocation',
                  'getLocation',
                  'updateAppMessageShareData'
              ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          })
      }
  })
})()

// router.beforeEach((to,from,next)=>{
//   if(to.name == 'home'){
//     let shareConfig = {
//       title: '橙蟹企服-财务人共享与协作平台', // 分享标题
//         link: window.location.href, // 分享链接
//         desc:'邀请你来投递职位',
//         imgUrl: 'https://oss.blockchain.orancrab.com/prod/static/sharelogo.jpg',
//     }
//     wx.ready(function(){
//       wx.updateAppMessageShareData(shareConfig);
//       wx.updateTimelineShareData(shareConfig);
//     })
//     wx.getLocation({
//       type: 'wgs84',
//       success:res=>{
//         console.log(res)
//       },
//       fail:error=>{
//         console.log(error)
//       }
//     })
//   } else if(to.name == 'detail') {
//     console.log(to.name)
//     let shareConfig = {
//       title: document.title, // 分享标题 
//       link: window.location.href, // 分享链接
//       desc:'邀请你来投递职位',
//       imgUrl: 'https://oss.blockchain.orancrab.com/prod/static/sharelogo.jpg',
//     }
//     wx.ready(function(){
//       wx.updateAppMessageShareData(shareConfig);
//       wx.updateTimelineShareData(shareConfig);
//     })
//   }
//   next()
// })
new Vue({
  router,
  render: h => h(App)
}).$mount("#app")
