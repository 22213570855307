<template>
  <div class="pagecontainer">
    <!-- <template v-if="results.shareType == 1"> -->
      <!-- <div class="title">{{results.title}}</div> -->
      <div class="itemcell">
        <p class="desc">活动时间：</p>
        <p class="value">{{results.activityBeginTime}}-{{results.activityEndTime}}</p>
      </div>
      <div class="itemcell" v-if="results.activityArea">
        <p class="desc">活动地址：</p>
        <p class="value">{{results.activityArea + results.activityAddr}}<span class="map iconfont icondizhi" @click.stop="openMap(results.activityArea + results.activityAddr,results.lat,results.lng)"></span></p>
      </div>
      <div class="content" v-html="results.content"></div>
      <!--  -->
    <!-- </template> -->
    <!-- <template v-else>
      <img :src="results.shareImage" class="shareImage" alt="">
    </template> -->
    <div class="positioncontent" v-if="showApply">
      <div class="mask_content" v-if="showDefault == 0 ">
        <div class="cell">
          <p><span style="color: #ED2E2E;margin-right:2px">*</span>姓名:</p>
          <input type="text" class="phone" v-model="paramas.name" placeholder="请输入姓名"/>
        </div>
        <div class="cell">
          <p><span style="color: #ED2E2E;margin-right:2px">*</span>电话:</p>
          <input type="text" class="phone" v-model="paramas.phone" placeholder="请输入手机号"/>
        </div>
        <div class="cell">
          <p><span style="color: #ED2E2E;margin-right:2px">*</span>公司名称:</p>
          <input type="text" class="phone" v-model="paramas.companyName" placeholder="请输入公司名称"/>
        </div>
        <div class="cell">
          <p><span style="color: #ED2E2E;margin-right:2px">*</span>职位:</p>
          <input type="text" class="phone" v-model="paramas.position" placeholder="请输入职位"/>
        </div>
    </div>
    <div class="mask_content" >
      <template v-if="results.formType == 2 && showDefault == 2">
        <div v-for="(item,index) in results.formList" :key="index">
          <div class="cell">
            <p>{{item.name}}:</p>
            <input type="text" class="phone" v-model="item.value" :placeholder="'请填写'+item.name"/>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-for="(item,index) in results.activityDiyListVo" :key="index">
          <div class="cell">
            <p><span style="color: #ED2E2E;margin-right:2px" v-if="item.isRequired">*</span>{{item.diyTitle}}:</p>
            <input type="text" class="phone" v-model="item['answerContent'+item.activityDiyId]" :placeholder="'请填写'+item.diyTitle"/>
          </div>
        </div>
      </template>
    </div>
    <div class="apply" @click="toApply">报名</div>
    </div>
    <!-- <div class="toapply">立即报名</div> -->
  </div>
</template>
<script>
export default {
  data(){
    return{
      showForm:false,
      showDefault:0, //0:默认4项，1:活动自定义 2:H5自定义
      paramas:{
        name:'',
        phone:'',
        companyName:'',
        position:'',
        openId:'',
        linkId:''
      },
      openId:'',
      activityId:'',
      results:'',
      payParams:{
        timeStamp: '', 
        nonceStr: '', 
        package: '',
        signType: '', 
        paySign: '', 
        appId:''
      },
      showApply:false
    }
  },
  methods:{
    openMap(value,latitude,longitude){
        this.$wx.openLocation({
            latitude : latitude, // 纬度，浮点数，范围为90 ~ -90
            longitude :longitude, // 经度，浮点数，范围为180 ~ -180。
            name : value, // 位置名
            address : '', // 地址详情说明
            scale : 28, // 地图缩放级别,整形值,范围从1~28。默认为最大
        });
    },
    applyClick(){
      this.showForm = true
    },
    closeClick(){
      this.showForm = false
      if(this.results.activityDiyListVo && this.results.activityDiyListVo.length){
        this.results.activityDiyListVo.map((item)=>{
          item['answerContent'+item.activityDiyId] = ''
          return item
        })
      } 
      this.paramas.phone = ''
      this.paramas.name = ''
      this.paramas.companyName = ''
      this.paramas.position = ''
    },
    
    toApply(){
      if(this.results.formType == 1){
        if(this.results.infoIsMustMethod == 1){
          if(!this.paramas.name){
            alert('请填写姓名')
            return
          }
          var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
          if(this.paramas.phone && !reg_tel.test(this.paramas.phone)){
              alert('请输入正确手机号')
              return
          }
          if(!this.paramas.phone){
            alert('请填写手机号')
            return
          }
          if(!this.paramas.companyName){
            alert('请填写公司名称')
            return
          }
          if(!this.paramas.position){
            alert('请填写职位') 
            return
          }
          let _params =[
            {name:'姓名',value:this.paramas.name},
            {name:'手机号',value:this.paramas.phone},
            {name:'公司名称',value:this.paramas.companyName},
            {name:'职位',value:this.paramas.position},
          ]
          this.paramas.json = JSON.stringify(_params)
        } else {
  
          let list = this.results.activityDiyListVo.filter((item)=>{
            return item.isRequired && !item['answerContent'+item.activityDiyId]
          })
          if(list.length){
            alert('请填写必填项!')
            return
          }
          this.paramas.json = JSON.stringify(this.results.activityDiyListVo)
        }
      } else {
        this.paramas.json = this.results.formJson ?  JSON.stringify(this.results.formList) : JSON.stringify(this.results.activityDiyListVo)
      }
      this.paramas.openId = this.openId
      console.log(this.paramas)
      let that = this
      if(this.results.price && this.results.price!=0) {
        this.$http.jsApiPay({
          openId:this.paramas.openId,
          sourceId:this.results.activityId,
          sourceType:2
        }).then(response=>{
          that.paramas.orderNum = response.orderNum
          this.$wx.chooseWXPay({  //此方法应放在调用后台统一下单接口成功后回调里面，接口返回  timeStamp，nonceStr，package，paySign等参数
            timestamp: response.timeStamp, 
            nonceStr: response.nonceStr, 
            package: response.package,
            signType: response.signType, 
            paySign: response.paySign, 
            appId:response.appId,  //此参数可不用
            success:  function(_responses){
              // console.log(_responses,'xxxxxxxxxxxxxxx')
              if (_responses.errMsg == "chooseWXPay:ok"){
                that.$http.applyActivity(that.paramas).then(res=>{
                  // console.log(res,'sssssssssssssssssss')
                  if(res.status == 1){
                    that.showForm = false
                    // if(that.results.activityDiyListVo && that.results.activityDiyListVo.length){
                    //   that.results.activityDiyListVo.map((item)=>{
                    //     item['answerContent'+item.activityDiyId] = ''
                    //     return item
                    //   })
                    // } 
                    // that.paramas.phone = ''
                    // that.paramas.name = ''
                    // that.paramas.companyName = ''
                    // that.paramas.position = ''
                    window.alert('报名成功')
                    setTimeout(()=>{
                      // window.location.replace(`${window.location.origin}/activity/${that.paramas.activityId}`)
                    },1000)
                  } else {
                    alert(res.message)
                  }
                })
              } else {  
                console.log(_responses)
                // location.reload();//支付失败 刷新界面
              }  
            },
            cancel: function(error) {  
              console.log(error)
            }  
          });
        })
      } else {
        if(this.results.formList && this.results.formList.length){
          let list = this.results.formList.filter((item)=>{
            return  !item.value
          })
          if(list.length){
            alert('请填写相关内容!')
            return
          }
        }
        this.$http.applyActivity(this.paramas).then(res=>{
          if(res.status == 1){
            this.showForm = false
            if(this.results.formType == 1){
              if(this.results.activityDiyListVo){
                this.results.activityDiyListVo.map((item)=>{
                  item['answerContent'+item.activityDiyId] = ''
                  return item
                })
              } else {
                this.paramas.phone = ''
                this.paramas.name = ''
                this.paramas.companyName = ''
                this.paramas.position = ''
              }
            }
            window.alert('报名成功')
            setTimeout(()=>{
              window.location.replace(`${window.location.origin}/activity/${this.paramas.linkId}`)
            },1000)
          } else {
            alert(res.message)
          }
        }) 
      }
    },
    GetParam(url, code) {
      url = url + "";
      let regstr = "/(\\?|\\&)" + code + "=([^\\&]+)/";
      let reg = eval(regstr);
      //eval可以将 regstr字符串转换为 正则表达式
      let result = url.match(reg);
      if (result && result[2]) {
          return result[2];
      }
    },
    async getOpenId(code){
      const { data } = await this.$http.authorize({code:code})
      this.openId = data
    },
    async getDetail(id){
      const { data } = await this.$http.getActivityShare({activityId:id})
      this.results = data
      document.title = this.results.title
      if(this.results.activityDiyListVo && this.results.activityDiyListVo.length){
        this.results.activityDiyListVo = this.results.activityDiyListVo.map(item=>{
          item['answerContent'+item.activityDiyId] = ''
          return item
        })
        this.results.isCustom = true
      } else {
        this.results.isCustom = false
      }
      this.setShareInfo()
    },
    getInfo(){
      if(window.location.href.indexOf('code') !== -1){
        let code = this.GetParam(window.location.href, "code")
        if(window.location.href.indexOf('?')!==-1){
          let index = window.location.href.indexOf('?')
          let url = window.location.href.substr(0,index)
          let list = url.split('/')
          let activityId = list[list.length-1]
          this.paramas.linkId = activityId
          this.getOpenId(code)
          // this.getDetail(activityId)
          this.getLinkInfo()
          this.increCount()
        }
      } else {
        let url = encodeURI(window.location.href)
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxca069443762320ec&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
      }
    },
    increCount(){
      this.$http.incrBrowCount({linkId:this.paramas.linkId})
    },
    setShareInfo(){
      let shareConfig = {
        title: this.results.title, // 分享标题
        link: window.location.origin + `/activity/${this.paramas.linkId}`, // 分享链接
        desc:this.results.describe||'',
        imgUrl: this.results.showImg,
      }
      let that = this
      this.$wx.ready(function(){
          that.$wx.updateAppMessageShareData(shareConfig);
          that.$wx.updateTimelineShareData(shareConfig);
      })
    },
    scrollToTop() { 
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      console.log(scrollTop)
      if(scrollTop > 260){
        this.showApply = true
      } else {
        this.showApply = false
      }
    },
    getLinkInfo(){
      this.$http.getLinkShare({linkId:this.paramas.linkId}).then(res=>{
        console.log(res)
        if(res.status === 1){
          if(res.data.sourceType == 2){
            this.results = res.data.vo
            if(this.results.formType == 1){
              if(this.results.activityDiyListVo && this.results.activityDiyListVo.length){
                this.showDefault = 1
              } else {
                this.showDefault = 0
              }
            } else {
              if(this.results.formJson && JSON.parse(this.results.formJson).length){
                this.showDefault = 2
              } else {
                this.showDefault = 1
              }
            }
            if(this.results.activityDiyListVo && this.results.activityDiyListVo.length){
              this.results.activityDiyListVo = this.results.activityDiyListVo.map(item=>{
                item['answerContent'+item.activityDiyId] = ''
                return item
              })
            }
            if(this.results.formType === 2){
              this.results.formList = this.results.formJson ?  JSON.parse(this.results.formJson) :[]
            }
            this.setShareInfo()
          }
        } else {
          alert(res.message)
        }
      })
    }
  },
  created(){
    // this.getDetail('217')
    // this.getLinkInfo()
    this.getInfo()
  },
  mounted () {
    window.addEventListener('scroll', this.scrollToTop)
  },
}
</script>

<style scoped>
.toapply{
  width: 100%;
  height: 52px;
  padding: 0 40px;
  position: fixed;
  bottom: 0;
}
input:focus {
　outline : none;
}
.pagecontainer{
  width: 100%;
  height: 100%;
  background: white;
  padding-bottom: 150px;
}
.shareImage{
  width: 100%;
  height: 100%;
}
.title{
  word-break: break-word;
  color: #1A1A1A;
  font-size: 19px;
  font-weight: bold;
  letter-spacing: 0.38px;
  line-height: 26px;
  text-align: left;
  margin: 0 16px;
}
.itemcell{
  display: flex;
  flex-direction: row;
  text-align: left;
  margin: 0 16px;
  flex-wrap: wrap;
  margin-top: 15px;
}
.desc{
  font-size: 15px;
  color: #999999;
  letter-spacing: 0.3px;
  line-height: 21px;
  /* flex: 1; */
}
.value{
  font-size: 15px;
  color: #1A1A1A;
  letter-spacing: 0.3px;
  line-height: 21px;
  flex: 1;
}
.value .map{
  color: #0A86F8;
  font-size: 14px;
}
.content{
  margin: 0 16px;
  margin-top: 15px;
}
.apply{
  /* position: fixed;
  bottom: 66px;
  right: 20px; */
  margin: 0 auto;
  height: 40px;
  width: 250px;
  margin-bottom: 20px;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(203,218,231,1);
  box-shadow: 0px 0px 6px 0px rgba(203,218,231,1);
  line-height: 40px;
  text-align: center;
  background: #0A86F8;
  color: white;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.35px;
  z-index: 44;
}
.maskview{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 45;
}
.positioncontent{
    position: fixed; 
    bottom: 0;
    left: 10px;
    right: 10px;
    background: white;
}
.mask_content{
    background: #FFFFFF;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    
}
.mask_content .close{
    font-size: 15px;
    position: absolute;
    top: 20px;
    right: 15px;
    width: 12px;
    height: 12px;
    color: #B9B9B9;
    /* background: rgba(185, 185, 185, 1); */
}
.cellcontent{
  margin-top: 60px;
}
.mask_content .cell{
  display: flex;
  flex-direction: row;
  margin: 0 8px;
  margin-bottom: 10px;
}
.cell p{
  font-size: 14px;
  color: #606266;
  line-height: 37px;
  /* flex: 1; */
  text-align: right;
  width: 75px;
  margin-right: 5px;
}
.mask_content .phone{
    font-size: 15px;
    min-wsidth: 251px;
    height: 37px;
    border-radius: 2px;
    border: 1px solid #CCCCCC;
    padding-left: 10px;
    /* color: #B9B9B9; */
}
.submit{
  position: absolute;
  bottom: 10px;
  left: 76px;
  width: 160px;
  height: 38px;
  line-height: 38px;
  background: #0A86F8;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 17px;
  color: #FEFFFF;
  text-align: center;
}
</style>