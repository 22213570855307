<template>
    <div>
        <div class="projectdetail" v-if="results">
            <div class="iconfont "></div>
            <div class="project-top" >
                <div class="title">{{results.companyName}}</div>
                <div class="des">
                    <template v-if="results.postStatus==1">					
                        <countdown v-if="results.betweenDeadlineStatus == 2" :index="index" :type="1" :remainingTime="results.betweenDeadline"></countdown>
                        <span class="des-status" v-else>{{results.postEndTime}}投递截止</span>
                    </template>
                </div>
                <div class="bottom">
                    <div class="row-view">
                        <div class="row-content">
                            <span class="name">岗位名称：</span>
                            <span class="value">{{results.postName}}</span>
                        </div>
                        <div class="row-content right">
                            <span class="name">地址：</span>
                            <span class="value">{{results.companyArea}}</span>
                            <span @click.stop="openMap(results.companyArea + results.companyAddr,results.lat,results.lng)" class="iconfont icondizhi map"></span>
                            <!-- <span class="icon iconfont iconbianxian-dianhua"></span> -->
                        </div>
                    </div>
                    <div class="row-view">
                        <div class="row-content">
                            <span class="name">汇报人：</span>
                            <span class="value">{{results.contactsUserName}}</span>
                        </div>
                        <div class="row-content right">
                            <span class="name">薪资：</span>
                            <span class="value">{{results.startPay ? results.startPay + '-' + results.endPay + 'K' : results.pay}}</span>
                        </div>
                    </div>
                    <div class="row-view">
                        <div class="row-content">
                            <span class="name">经纪人：</span>
                            <span class="value">{{results.brokerUserName}}</span>
                            <span class="iconfont iconbianxian-dianhua map" @click="callphone(results.brokerUserPhone)"></span>
                        </div>
                        <div class="row-content right">
                            <span class="name">公司规模：</span>
                            <span class="value">{{results.companyScaleStr}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="line"></div>
            <div class="project-center">
                <div class="theme" v-for="(item,index) in results.postThemeList" :key="index" >
                    <div class="theme-title" >
                        <span class="title" >{{item.title}}</span>
                    </div>
                    <div class="theme-content" >
                        <span>{{item.desc}}</span>
                    </div>
                </div>
            </div>
            <div class="line"></div>
        </div>
        <div class="fixedbottom" @click="showminiProgram">申请职位</div>
        <!-- <div class="alertdiv" v-if="showalert">
            <div class="content">
                <img src="../assets/code.png" alt="">
                <p>长按识别小程序码进入小程序申请职位</p>
            </div>
            <img src="../assets/delte.png" alt="" class="deleteimg" @click="cancel">
        </div> -->
        <div class="maskview" v-if="showalert">
            <div class="mask_content">
                <div class="close" @click="closeClick">x</div>
                <input type="text" class="phone" v-model="phone" placeholder="请输入手机号"/>
                <div class="file-box">
                    <input type="file" class="file-btn"  ref="inputer" @change="onFileChange" >
                    <div class="file-name">{{filename}}</div> 
                </div>
                <div class="submit" @click="toApply">提交申请</div>
            </div>
        </div>
        <!-- <form action="" method="post">
            <p><label>手机号:<input type="text"></label></p>
            <p><label>简历<input type="text"></label></p>
        </form> -->
    </div>
</template>
<script>

import countdown from '../components/countdown.vue'
import axios from 'axios'

export default {
    data(){
        return{
            results:'',
            postId:'',
            showalert:false,
            filename:'上传个人简历',
            resume:'',
            phone:''
        }
    },
    components:{
        countdown
    },
    methods:{
        closeClick(){
            this.showalert = false
            this.phone = ''
            this.resume = ''
            this.filename = '上传个人简历'
        },
        getDetail(){
            this.$http.getPositionDetail({postId:this.postId}).then(res=>{
                if(res.status == 1){
                    this.results = res.data
                    document.title = res.data.companyName
                    let shareConfig = {
                        title: res.data.companyName, // 分享标题 
                        link: window.location.href, // 分享链接
                        desc:'邀请你来投递职位',
                        imgUrl: 'https://oss.blockchain.orancrab.com/prod/static/sharelogo.jpg',
                    }
                    let that = this
                    this.$wx.ready(function(){
                        that.$wx.updateAppMessageShareData(shareConfig);
                        that.$wx.updateTimelineShareData(shareConfig);
                    })
                } else {
                    this.$router.replace('/')
                }
            })
        },
        callphone(phone){
            window.location.href = `tel://${phone}`
        },
        showminiProgram(){
            this.showalert = true
        },
        cancel(){
            this.showalert = false
        },
        openMap(value,latitude,longitude){
            this.$wx.openLocation({
                latitude : latitude, // 纬度，浮点数，范围为90 ~ -90
                longitude :longitude, // 经度，浮点数，范围为180 ~ -180。
                name : value, // 位置名
                address : '', // 地址详情说明
                scale : 28, // 地图缩放级别,整形值,范围从1~28。默认为最大
            });
        },
        onFileChange(e){
            var formData = new FormData()
            formData.append('file',e.target.files[0])
            axios({
                method:'post',
                url:'http://blockchain.orancrab.com/blockchain_h5_api/uploadFile/uploadFile',
                data:formData,
                
            }).then(res=>{
                if(res.data.status == 1){
                    this.filename = res.data.data.fileName
                    this.resume = res.data.data.filePath
                }
            })
        },
        toApply(){
            var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
            if(!this.phone && !reg_tel.test(this.phone)){
                window.alert('请输入正确手机号')
                return
            }
            if(!this.resume){
                window.alert('请上传简历')
                return
            }
            this.$http.applyPost({
                postId:this.postId,
                resume:this.resume,
                phone:this.phone
            }).then(res=>{
                if(res.status == 1){
                    this.showalert = false
                    this.phone = ''
                    this.resume = ''
                    this.filename = '上传个人简历'
                    window.alert('职位申请成功!')
                } else{
                    window.alert(res.message)
                }
            })
        }
    },
    created(){
        this.postId = this.$route.params.postId
        this.getDetail()
        
    }
}
</script>
<style scoped>
.projectdetail{
    padding-bottom: 50px;
}
.line{
    height: 10px;
    background: #F6F6F6;
    width: 100%;
}
.fixedbottom{
    position: fixed;
    bottom: 0;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 15px;
    color: #0A86F8;
    background: white;
    border-top: 1px solid #ddd;
    width: 100%;
}
.project-top .title{
    margin: 0 16px;
    margin-top: 16px;
    color: #1a1a1a;
    font-size: 19px;
    letter-spacing: 0.38px;
    font-weight: bold;
    word-break: break-word;
    text-align: left;
}
.project-top .des{
    margin: 0 16px;
    margin-top: 8px;
    text-align: left;
}
.des-status{
    color: #0A86F8;
    font-size: 15px;
}
.bottom{
    margin: 0 16px;
    margin-top: 8px;
    border-top: 1px solid #D1D5D9;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}
.bottom .row-view{
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

}
.row-view .row-content{
    font-size: 15px;
    line-height: 21px;
    text-align: left;
    flex: 1;
}
.row-content .name{
    color: #999999;
}
.row-content .value{
    color: #111111;
    flex-wrap: nowrap;
}
.row-content .map{
    color: #0A86F8;
    font-size: 14px;
    margin-left: 2px;
}

.theme-title{
    margin: 0 16px;
    padding-top: 15px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid #D1D5D9;
    word-break: break-word;
}
.project-center .title{
    word-break: break-word;
    color: #1A1A1A;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: 0.34px;
    font-weight: bold;
}
.theme-content{
    margin: 0 16px;
    color: #444444;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 24px;
    margin-top: 8px;
    padding-bottom: 10px;
    text-align: left;
}
.theme-content span{
    word-break: break-word;
}
.theme:first-child .theme-title{
    border-top: none;
}
.alertdiv{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.content{
    margin:0 52px;
    margin-top: 143px;
    height: 208px;
    background: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 98;
}
.content img{
    height: 139px;
    width: 139px;
    margin-top: 20px;
    /* background: red; */
}
.content p{
    font-size: 13px;
    color: #666;
    margin: 0 10px;
    margin-top: 6px;
    text-align: left;
}
.deleteimg{
    margin-top: 24px;
    width: 22px;
    height: 22px;
    z-index: 99;
}

.maskview{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
}
.maskview .mask_content{
    position: absolute;
    left: 32px;
    right: 32px;
    top: 110px;
    height: 247px;
    background: #FFFFFF;
    border-radius: 4px;
}
.mask_content .close{
    font-size: 15px;
    position: absolute;
    top: 20px;
    right: 15px;
    width: 12px;
    height: 12px;
    color: #B9B9B9;
    /* background: rgba(185, 185, 185, 1); */
}
.mask_content .phone{
    font-size: 15px;
    width: 251px;
    height: 37px;
    border-radius: 2px;
    border: 1px solid #CCCCCC;
    padding-left: 10px;
    margin-top: 62px;
    color: #B9B9B9;
}
.file-box{
    position: absolute;
    margin-top: 30px;
    font-size: 15px;
    width: 251px;
    height: 37px;
    left: 30px;
    border-radius: 2px;
    border: 1px solid #CCCCCC;
    overflow: hidden;
    color:black;
    background-color: white;
}
.file-btn{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    outline: none;
    background-color: transparent;
    filter:alpha(opacity=0);
    -moz-opacity:0;
    -khtml-opacity: 0;
    opacity: 0;
}
.file-name{
    line-height:37px;
    text-align:left;
    padding-left:10px;
    color: #B9B9B9;
}
.submit{
    position: absolute;
    bottom: 27px;
    left: 76px;
    width: 160px;
    height: 38px;
    line-height: 38px;
    background: #0A86F8;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-size: 17px;
    color: #FEFFFF;
    text-align: center;
}

</style>