<template>
    <div class="container">
        <div class="wechat" @click="wechatClick"></div>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    methods:{
        wechatClick(){
            
        }
    },
    created(){

    }
}
</script>

<style scoped>
.container{
    width: 100%;
    height: auto;
}
.wechat{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: greenyellow;
    margin: 0 auto;
    margin-top: 200px;
}
</style>