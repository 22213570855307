import axios from 'axios'
import qs from 'qs'

axios.defaults.baseURL = process.env.VUE_APP_CURRENTMODE == 'production' ? 'http://blockchain.orancrab.com/blockchain_h5_api' :'https://cx.blockchain.hyonline.online/blockchain_h5_api_dev'
axios.defaults.timeout = 10000

axios.interceptors.request.use(
    config=>{
        let token = localStorage.getItem('token')

        if(token){
            config.headers['userToken'] = token
        }
        // if(config.url == '/uploadFile/uploadFile'){
        //     config.headers = {
        //         'Content-Type' : "multipart/form-data"
        //     }
        // }
        return config   
    },
    error =>{
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    response=>{
        if(response.status == 200){
            return Promise.resolve(response)
        } else {
            return Promise.reject(response)
        }
    },
    error=>{
        console.log(error)
    }
)


/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params){    
    return new Promise((resolve, reject) =>{        
        axios.get(url, {            
            params: params        
        })        
        .then(res => {            
            resolve(res.data);        
        })        
        .catch(err => {            
            reject(err.data)        
        })    
    });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params) {    
    return new Promise((resolve, reject) => {         
        axios.post(url, qs.stringify(params))        
        .then(res => {            
            resolve(res.data);        
        })        
        .catch(err => {            
            reject(err.data)        
        })    
    })
}
