<template>
    <div class="container">
        <div class="topcenter">
            <div class="top">
                <img src="../assets/logo.png" alt="" class="logo">
                <img src="../assets/logotitle.png" alt="" class="logotitle">
            </div>
            <img src="../assets/user.png" v-if="!userInfo.headimgurl" alt="" class="login" @click="openwechat">
            <img :src="userInfo.headimgurl" v-else alt="" class="login">
        </div>
        <div class="iconfont icondizhi"></div>
        <position  :extra.sync="list" @todetail="todetailClick"></position>
    </div>

</template>
<script>
 
import position from '../components/position'
export default {
    data(){
        return{
            list:[],
            query:{
                pageNum:1,
                pageSize:10
            },
            userInfo:{
                headimgurl:''
            },
        }
    },
    components:{
        position
    },
    methods:{
        
        todetail(){
            this.$router.push({path: '/detail'});
        },
        todetailClick(item){
            if(localStorage.getItem('token')){
                this.$router.push({path: `/detail/${item.postId}`});
            } else {
                let url = encodeURI(window.location.href)
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxca069443762320ec&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
            }
        },
        getlist(){
            this.$http.getPositionList(this.query).then(res=>{
                if(res.status == 1){
                    if(this.query.pageNum == 1){
                        this.list = res.data.list
                    } else {
                        this.list = this.list.concat(res.data.list)
                    }
                }
            })
        },
        openwechat(){
            let url = encodeURI(window.location.href)
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxca069443762320ec&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
        },
        GetParam(url, code) {
            url = url + "";
            let regstr = "/(\\?|\\&)" + code + "=([^\\&]+)/";
            let reg = eval(regstr);
            //eval可以将 regstr字符串转换为 正则表达式
            let result = url.match(reg);
            if (result && result[2]) {
                return result[2];
            }
        },
        handleScroll(){
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
            //变量windowHeight是可视区的高度
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
            //滚动条到底部的条件
            if(scrollTop+windowHeight==scrollHeight &&this.list.length !==0){
                this.query.pageNum++
                this.getlist() // 加载的列表数据
            }
        },
 
    },
    created(){
        let shareConfig = {
        title: '橙蟹企服-财务人共享与协作平台', // 分享标题
            link: window.location.origin, // 分享链接
            desc:'邀请你来投递职位',
            imgUrl: 'https://oss.blockchain.orancrab.com/prod/static/sharelogo.jpg',
        }
        let that = this
        this.$wx.ready(function(){
            that.$wx.updateAppMessageShareData(shareConfig);
            that.$wx.updateTimelineShareData(shareConfig);
        })
       
        let code = this.GetParam(window.location.href, "code")
        if(code){
            this.$http.getUserInfo({code:code}).then(res=>{
                if(res.status == 1){
                    this.userInfo = res.data
                    localStorage.setItem('token',res.data.userToken)
                    localStorage.setItem('info',JSON.stringify(this.userInfo))
                } else {
                     this.userInfo = JSON.parse(localStorage.getItem('info'))
                }
            })
        }
        if(JSON.parse(localStorage.getItem('info'))){
            this.userInfo = JSON.parse(localStorage.getItem('info'))
        }
        this.getlist()
        window.addEventListener('scroll',this.handleScroll)
       
    },
  
    destroyed(){
        window.removeEventListener('scroll', this.handleScroll)
    }
}
</script>

<style scoped>
.map{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    bottom: 0;
}
.topcenter{
    position: fixed;
    top: 0;
    height: 60px;
    width: 100%;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    border-bottom: 1px solid #ddd;
}
.topcenter .logo{
    width: 40px;
    height: 25px;
    /* background: red; */
    margin-left: 10px;
}
.top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.topcenter .logotitle{
    width: 162px;
    height: 40px;
    /* background: green; */
    
}
.topcenter .login{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
}

.maskview{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
}
.maskview .mask_content{
    position: absolute;
    left: 32px;
    right: 32px;
    top: 110px;
    height: 247px;
    background: #FFFFFF;
    border-radius: 4px;
}
.mask_content .close{
    font-size: 15px;
    position: absolute;
    top: 20px;
    right: 15px;
    width: 12px;
    height: 12px;
    color: #B9B9B9;
    /* background: rgba(185, 185, 185, 1); */
}
.mask_content .phone{
    font-size: 15px;
    width: 251px;
    height: 37px;
    border-radius: 2px;
    border: 1px solid #CCCCCC;
    padding-left: 10px;
}
.file-box{
    position: absolute;
    margin-top: 30px;
    font-size: 15px;
    width: 251px;
    height: 37px;
    left: 30px;
    border-radius: 2px;
    border: 1px solid #CCCCCC;
    overflow: hidden;
    color:black;
    background-color: white;
}
.file-btn{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    outline: none;
    background-color: transparent;
    filter:alpha(opacity=0);
    -moz-opacity:0;
    -khtml-opacity: 0;
    opacity: 0;
}
.file-name{
    line-height:37px;
    text-align:left;
    padding-left:10px;
    color: #B9B9B9;
}
.submit{
    position: absolute;
    bottom: 27px;
    left: 76px;
    width: 160px;
    height: 38px;
    line-height: 38px;
    background: #0A86F8;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-size: 17px;
    color: #FEFFFF;
    text-align: center;
}

</style>