<template>
    <div class="containers" >
		<div class="project-content" v-for="(item,index) in extra" :key="index" @click="pushPositionDetail(item)">
			<span class="title">{{item.companyName}}</span>
			<div class="des">
				<countdown v-if="item.betweenDeadlineStatus == 2" :index="index" :type="1" :remainingTime="item.betweenDeadline"></countdown>
				<span class="des-status" v-if="(item.betweenDeadlineStatus == 1 || item.betweenDeadlineStatus ==  3) ">{{item.postEndTime}}投递截止</span>
				<span class="des-status" v-else-if="item.betweenDeadlineStatus == 4 && item.bidprojectStatus == 2">公布中</span>
				<span v-if="item.isApply > 0" style="color:#444444;font-weight:bold;font-size: 15px;">已投递</span>
		
			</div>
			<div class="one_lines"></div>
			<div class="bottom">
				<div class="row-div">
					<div class="row-content">
						<span class="name">岗位名称：</span>
						<span class="value">{{item.postName}}</span>
					</div>
					<div class="row-content right">
						<span class="name">地址：</span>
						<span class="value">{{item.companyArea}}</span>
						<span @click.stop="openMap(item.companyArea+item.companyAddr,item.lat,item.lng)" class="map iconfont icondizhi"></span>
					</div>
				</div>
				<div class="row-div">
					<div class="row-content">
						<span class="name">汇报人：</span>
						<span class="value">{{item.contactsUserName}}</span>
					</div>
					<div class="row-content right">
						<span class="name">薪资：</span>
						<span class="value">{{item.startPay ? item.startPay + '-' + item.endPay + 'K' : item.pay}}</span>
					</div>
				</div>
				<div class="row-div">
					<div class="row-content">
						<span class="name">经纪人：</span>
						<span class="value">{{item.brokerUserName}}</span>
					</div>
					<div class="row-content right">
						<span class="name">公司规模：</span>
						<span class="value">{{item.companyScaleStr}}</span>
					</div>
				</div>
			</div>
			<div class="line">
				
			</div>
		</div>
    </div>
</template>

<script>

import countdown from './countdown.vue'

export default {
    data(){
        return{

        }
    },
    components:{
        countdown
    },
    props:{
        extra:{
            type:Array,
        }
    },
    methods:{
        pushPositionDetail(item){
            this.$emit('todetail',item)
        },
        openMap(value,latitude,longitude){
            this.$wx.openLocation({
                latitude : latitude, // 纬度，浮点数，范围为90 ~ -90
                longitude :longitude, // 经度，浮点数，范围为180 ~ -180。
                name : value, // 位置名
                address : '', // 地址详情说明
                scale : 28, // 地图缩放级别,整形值,范围从1~28。默认为最大
            });
        }
    }
}
</script>

<style scoped>
.containers{
    width: 100%;
    margin-top: 60px;
}
.project-content{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}
.title{
    color: #111111;
    font-size: 17px;
    letter-spacing: 0.34px;
    line-height: 24px;
    font-weight: bold;
    margin: 0 16px;
    word-break: break-word;
    text-align: left;
}
.des{
    margin: 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
}
.des .des-title{
    color: #0A86F8;
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 21px;
}
.des .des-status{
    color: #0A86F8;
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 21px;
}
.des .outbid{
    color: #0A86F8;
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 21px;
}

.one_lines{
    position: relative;
    background: #D1D5D9;
    margin: 0 16px;
    margin-top: 5px;
}
.one_lines::after{
    position: absolute; 
    content: '';
    width: 100%;
    left: 0;
    bottom: 0;
    height: 1px;
    background-color: #D1D5D9;
    -webkit-transform: scale(1, 0.5);
    transform: scale(1, 0.5);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
}
.bottom{
    margin: 0 16px;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    
}
.row-div{
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.row-content{
    font-size: 15px;
    line-height: 21px;
    text-align: left;
    flex: 1;
    
}
.row-content .name{
    color: #999999;
}
.row-content .value{
    color: #111111;
    flex-wrap: nowrap;
}
.row-content .map{
    color: #0A86F8;
    font-size: 14px;
}
.line{
    height: 10px;
    background: #F6F6F6;
    width: 100%;
}
.pass{
    height: 44px;
    background: #F6F6F6;
    padding-left: 16px;
    line-height: 34px;
    color: #111111;
    font-size: 17px;
    letter-spacing: 0.16px;
    font-weight: bold;
}
</style>