<template>
  <div class="container">
    <div class="questiontop">
      <p class="questionTitle">{{extra.title}}</p>
      <p class="questioncontent" v-html="extra.desc"></p>
      <!-- <div class="left">
				<img :src="questionInfo.createUserHeadImg" />
				<p class="name">{{questionInfo.createUserName}}</p>
			</div> -->
    </div>
    <div style="height: 10px;background: #F6F6F6;width: 100%;"></div>
    <div v-if="list.length">
      <div class="content" v-for="(item,index) in list" :key="index">
        <div class="top">
          <div class="left">
            <img :src="item.headImg" />
            <span class="name">{{item.name}}</span>
          </div>
        </div>
        <div class="_content" v-html="item.content"></div>
        <div class="comment-user-list" v-if="item.answerCommentVoList.length">
          <div class="comment-content">						
            <div class="comment-cell" v-for="(items,idx) in item.answerCommentVoList" :key="idx" >
              <template v-if="items.commentType==2">
                <span class="name" >{{items.name}}：</span>回复<span class="name" >@{{items.replyToUserName}}：</span>
              </template>
              <span class="name" v-else >{{items.name}}：</span>
              <span class="value" >{{items.content}}</span>
            </div>
            <div class="more-comment" v-if="item.commentCount>2" @click="tomoreComment(item.answerId)">
              <span>全部{{item.commentCount}}条评论</span>
            </div>
          </div>
        </div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      list:[],
      extra:'',
      query:{
        pageNum:1,
        pageSize:10,
        questionId:''
      }
    }
  },
  methods:{
    getDetail(){
      this.$http.getquestionDetail(this.query).then(res=>{
        if(res.status == 1){
          if(this.query.pageNum == 1){
            this.extra = res.data.extra
            this.list = res.data.list
            document.title = this.extra.title
            let shareConfig = {
                title: this.extra.title, // 分享标题 
                link: window.location.href, // 分享链接
                desc:'',
                imgUrl: 'https://oss.blockchain.orancrab.com/prod/static/sharelogo.jpg',
            }
            let that = this
            this.$wx.ready(function(){
                that.$wx.updateAppMessageShareData(shareConfig);
                that.$wx.updateTimelineShareData(shareConfig);
            })
          } else {
            this.list = this.list.concat(res.data.list)
          }
        } else {
          this.$router.replace('/answer')  
        }
      })
    },
    handleScroll(){
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
      //滚动条到底部的条件
      console.log(scrollTop+windowHeight,scrollHeight,windowHeight)
      if(scrollTop+windowHeight==scrollHeight &&this.list.length !==0){
          this.query.pageNum++
          this.getDetail() // 加载的列表数据
      }
  },
  },
  created(){
    this.query.questionId = this.$route.params.questionId
    this.getDetail()
    window.addEventListener('scroll',this.handleScroll)
  },
  destroyed(){
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style scoped>
.container{
  height: 100%;
  width: 100%;
  background: white;
}
.questiontop{
  margin: 0 8px;
  margin-top: 17px;
}
.questiontop .questionTitle{
  color: #1a1a1a;
  font-size: 19px;
  font-weight: bold;
  letter-spacing: 0.38px;
  line-height: 26px;
  text-align: left;
}
.questiontop .questioncontent{
  margin-top: 8px;
  text-align: left;
  font-size: 16px;
  padding-bottom: 10px;
}
.content{
  display: flex;
	flex-direction: column;
}
.content ._content{
  margin: 0 16px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  word-wrap: break-word;
  text-align: left;
}
.top{
  margin: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
}
.top .left{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.left img{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
}
.left .name{
  color: #1A1A1A;
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 20px;
  font-weight: bold;
}
.comment-user-list{
  margin: 0 16px;
  margin-top: 10px;
}
.comment-content{
  width: 100%;
  background: #F6F6F6;
  padding: 5px 0;
}
.comment-cell{
  margin: 5px 12px;
  font-size: 15px;
  text-align: left;
}
.comment-cell .name{
  color: #3E83C2;
}
.comment-cell .value{
  flex: 1;
  color: #1A1A1A;
  word-break: break-all;
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 23px;
}
.line{
  height: 8px;
  width: 100%;
  background:#F6F6F6 ;
  margin-top: 5px;
}
.more-comment{
  margin-left: 12px;
  color: #444444;
  font-size: 15px;
}
</style>